<template>
  <v-date-picker v-model="dates" scrollable range locale="es" :first-day-of-week=1 :max="today"
    :min="dateInit || undefined" :allowed-dates="allowedDates">
    <v-icon v-if="dates" color="primary" @click="reset" v-text="'mdi-reload'"></v-icon>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="saveAndClose(false)">
      Cancelar
    </v-btn>
    <v-btn text color="primary" @click="saveAndClose(true)" v-if="dates">
      OK
    </v-btn>
  </v-date-picker>
</template>

<script>
export default {
  name: 'CalendarMultiple',
  data: () => ({
    dates: null,
    dateInit: null,
  }),
  methods: {

    reset(){
      this.dates = null
    },
    allowedDates(date) {
      if (!this.dates || !this.dates[0]) return true;
      const dateToCheck = new Date(date);
      return dateToCheck >= new Date(this.dates[0]);
    },

    saveAndClose(update){
      this.$emit("saveAndClose", update ? this.dates : null)
    }
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10)
    }
  }
};
</script>