<template>
    <vue-html2pdf :enable-download="true" :paginate-elements-by-height="1400" :filename="name" :pdf-quality="2"
        :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait" ref="html2Pdf">
        <section slot="pdf-content">
            <section>
                <div class="header">
                    <div class="column logo">
                        <img class="topbar-logo" src="../assets/logo-giser.png" alt="Giser Uniformes" />
                    </div>
                    <div class="column title">
                        <h3>{{ title}}</h3>
                    </div>
                    <div class="column date-time">
                        <span id="datetime">{{ inf }}</span>
                    </div>
                </div>
            </section>
            <hr>
            <section v-for="(group, groupIndex) in groupedDesserts" :key="groupIndex">
                <v-data-table :headers="headers" :items="group" hide-default-footer :items-per-page=-1
                    class="elevation-1 custom-font">
                </v-data-table>
                <div v-if="groupIndex < group.length" class="html2pdf__page-break" />
            </section>
        </section>
    </vue-html2pdf>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
export default {
    name: 'ReportDataPdfBasic',
    components: {
        VueHtml2pdf,
    },
    props: {
        name: String,
        title: String,
        elementsPerPag: Number,
        headerAll: Boolean,
        inf: String,
        headers: {
            type: Array, default: () => []
        },
        items: {
            type: Array, default: () => []
        }, 
    },
    data: () => ({}),
    computed: {
        groupedDesserts() {
            const grouped = [];
            for (let i = 0; i < this.items.length; i += this.elementsPerPag) {
                grouped.push(this.items.slice(i, i + this.elementsPerPag));
            }
            return grouped;
        },
    },
    methods: {
        generatePdf() {
            this.$refs.html2Pdf.generatePdf();
        }
    },
    mounted() {
        console.log("activado")
    }
}
</script>