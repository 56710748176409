<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ titulo }} - Rut: {{ dataForm.rut }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="6" sm="6" md="6" class="pb-0">
            <v-text-field
              v-model="dataForm.firstName"
              label="Nombres"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>
            <v-col cols="3" sm="3" md="3" class="pb-0">
            <v-text-field
              v-model="dataForm.fatherName"
              label="Primer Apellido"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>

            <v-col cols="3" sm="3" md="3" class="pb-0">
            <v-text-field
              v-model="dataForm.motherName"
              label="Segundo Apellido"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>
            
            <v-col cols="5" sm="5" md="5" class="pb-0">
            <v-text-field
              v-model="dataForm.position"
              label="Cargo - Rol"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>

            <v-col cols="5" sm="5" md="5" class="pb-0">
            <v-text-field
              v-model="dataForm.costCenter"
              label="Centro de Costo"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
              </v-col>

            <v-col cols="2" sm="2" md="2" class="pb-0">
            <v-text-field
              v-model="dataForm.gender"
              label="Genero"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>

            <v-col cols="4" sm="4" md="4" class="pb-0">
            <v-text-field
              v-model="dataForm.email"
              label="Email"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>

            <v-col cols="3" sm="3" md="3" class="pb-0">
            <v-text-field
              v-model="dataForm.managerRut"
              label="Rut Jefe"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>  

            <v-col cols="5" sm="5" md="5" class="pb-0">
            <v-text-field
              v-model="dataForm.managerName"
              label="Nombre Jefe"
              outlined
              required
              :disabled="disabledForm"
            ></v-text-field>
            </v-col>          
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">
        Volver
      </v-btn>
      <v-btn v-if="!disabledForm" color="blue darken-1" text @click="save">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import UserProfile from "../../data";
import router from "../../router";
import ImageInput from '../../components/ImageInput.vue';
export default {
  components: {
    ImageInput
  },
  data: () => ({
    user: UserProfile.getUserData(),
    search: "",
    dialog: false,
    dialogDelete: false,
    avatar: null,
    avatarTmp: null,
    chosenFile: null,
    lugares: ["Cliente", "Instalación"],
    tiposPlanificacion: ["Programado", "No programado"],
    tiposCreador: [
      "Cliente administrador",
      "Cliente usuario final",
      "FC de la locación",
      "FC fuera de la locación",
    ],
    tiposServicios: [
      "Aseo tradicional",
      "Aseo terminal",
      "Aseo recurrente",
      "Programa de aseo",
      "Vidrios a nivel",
      "Vidrios en altura",
      "Limpieza en altura",
    ],
    gruposTareas: ["Fin de semana", "Cada 15 días", "Cada 30 días"],
    parametrosLugar: ["Dirección", "Recinto", "Piso", "Zona/Área"],
    tiposTicket: [
      "Capacitación",
      "Tarea de RRHH",
      "Tarea administrativa",
      "Aviso cambio RRHH",
      "Mantenimiento de equipos",
    ],
    asignacion: ["Cuadrilla", "Persona", "Cargo"],
    estadoTicket: ["No leído", "Abierto", "Resuelto", "Cerrado"],
    headers: [
      {
        text: "Título",
        align: "start",
        sortable: false,
        value: "titulo",
      },
      { text: "Tipo de ticket", value: "tipo" },
      // { text: "Descripción", value: "descripcion" },
      { text: "Lugar", value: "lugar" },
      { text: "Planificación", value: "tipoPlanificacion" },
      { text: "Tipo de Creador", value: "tipoCreador" },
      { text: "Servicio", value: "tipoServicio" },
      // { text: "Grupo de tareas", value: "grupoTarea" },
      { text: "Asignación", value: "asignado_a" },
      // { text: "Estado", value: "estado" },
      { text: "Creado por", value: "creado_por" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  props: {
    titulo: {
      type: String,
      default: "Info Empleado",
    },
    dataForm: {
      type: Object,
      default: {},
    },
    disabledForm: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {    
    if(this.dataForm?.photo != "") {
      this.avatarTmp = this.dataForm.photo;
    }
  },
  computed: {},

  watch: {},

  created() {

  },

  methods: {
    close() {
      this.$emit("recibeTickect", undefined)
      this.avatar = null;
      //this.avatarTmp = null;
      this.chosenFile = null;
    },

    save() {
      this.dataForm.chosenFile = this.chosenFile;
      this.$emit("recibeTickect", this.dataForm)
    },
  },
  watch: {
      avatar(){
        if(this.avatar){
          this.avatarTmp = this.avatar.imageURL
          this.chosenFile = this.avatar.imageFile
        }
      },
    }
};
</script>

<style lang="scss">
.combo-parametros {
  .v-select__selections {
    min-height: 56px !important;
  }
}
.theme--dark.v-chip.default {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}
.v-text-field__details {
  display: none;
}
</style>
