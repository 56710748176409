<template>
    <div>
        <v-card flat>
            <v-card-title>
                <span class="text-h5">Solicitud: {{ reference }} {{ originLabel ? `Origen: ${originLabel}` : '' }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="3" sm="3" md="3" class="pb-0">
                            <v-autocomplete
                                v-model="employee"
                                :items="itemsEmployees"
                                label="Empleado"
                                outlined
                                auto-select-first
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="2" sm="2" md="2" class="pb-0">
                            <v-text-field
                                v-model="quantity"
                                label="Cantidad"
                                outlined
                                required
                                type="number"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="3" sm="3" md="3" class="pb-0">
                            <v-autocomplete
                                v-model="product"
                                :items="products"
                                label="Codigo Producto"
                                auto-select-first
                                outlined
                                ref="cod"
                            ></v-autocomplete>
                        </v-col>                        
                        <v-col cols="2" sm="2" md="2" class="pb-0">
                            <v-text-field 
                            v-model="reference" 
                            label="Nota" 
                            outlined 
                            class="mb-3"
                        ></v-text-field>
                        </v-col>

                        <v-col cols="2" sm="2" md="2">
                            <v-icon
                                color="success"
                                @click="onList"
                                v-text="'mdi-check-outline'"
                                :disabled="!isNewItemOk"
                            ></v-icon> - 
                            <v-icon v-if="disableRef"
                                color="warning"
                                @click="onEdit"
                                v-text="'mdi-circle-edit-outline'"
                            ></v-icon>
                        </v-col>
                    </v-row>
                    
                    <v-data-table
                        :headers="headers"
                        :items="productsAggregate"
                        
                        class="elevation-1 custom-font"
                    >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelStock">
                    Cancelar
                </v-btn>
                <v-btn :disabled="!isOk" color="blue darken-1" text @click="saveStock">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
export default {
    name: 'request',
    props: {
        products: {
            type: Array, default: () => []
        },
        employees: {
            type: Array, default: () => []
        }, 
    },
    data: () => ({
        selectedRef: null,
        product: 0,
        quantity: 0,
        employee: null,
        reference: '',
        itemsProducts: [],
        itemsEmployees: [],
        productsAggregate:[],
        disableRef: false,
        originLabel: null,
        headers: [
            { text: 'Empleado', value: 'employee' },
            { text: 'Cantidad', value: 'quantity' },
            { text: 'Producto', value: 'product' },
            { text: 'Observacion', value: 'note' },
            { text: "Acciones", value: "actions", sortable: false },
        ],
    }),

    methods: {
        createdValuesItems(){
            this.itemsEmployees = this.employees.map(employee => ({
                text: `${employee.rut} - ${employee.firstName} ${employee.fatherName} ${employee.motherName}`,
                value: employee.id
            }))
        },

        async onList(){
            const quantity = parseInt(this.quantity)
            const product = this.products.find(product => product.value === this.product)
            const employee = this.itemsEmployees.find(employee => employee.value === this.employee)
            const exist = this.productsAggregate.findIndex(product => product.productId === this.product && product.employeeId === this.employee)
            if(exist !== -1) {
                this.productsAggregate[exist].quantity += quantity;
            } else {
                this.productsAggregate.push({
                    productId: this.product,
                    product: product.text,
                    quantity,
                    employeeId: this.employee,
                    employee: employee.text,
                    note: this.reference,
                });
            }
            this.quantity = 0;
            this.product = 0;
            this.disableRef = true;
            if(!this.originLabel) {
                const origin = this.itemsProviders.find(provider => provider.value === this.provider)
                if(origin) this.originLabel = origin.text
            }
        },
        deleteItem(item){
            const index = this.productsAggregate.findIndex(product => product.id === item.id)
            if (index !== -1) {
                this.productsAggregate.splice(index, 1);
            }
        },
        onEdit(){
            this.disableRef = false;
            this.originLabel = null;
        },
        cancelStock(){
            this.$emit("cancelStock", {})
        },
        saveStock(){
            const stock = {
                items: this.productsAggregate,
                reference: this.reference,
                origin: this.provider
            }
            console.log(stock)
            this.$emit("saveStock", stock)
        }
    },

    mounted() {
        this.createdValuesItems();
    },

    computed: {
        isNewItemOk(){
            return this.product !== 0 && parseInt(this.quantity) !== 0;
        },
        isOk(){
            return this.productsAggregate.length > 0 && this.employee !== null && this.reference != '';
        }
    }
}
</script>